<template>
  <div class="step-way-container"
       :style="{width: `calc(100%/${total})`}"
       :class="delivery ? 'step-way-container-height1' : 'step-way-container-height'"
  >
    <div class="date-status-container d-flex justify-content-end">
      <div v-if="date" class="date-status">
        {{ date.split('.').slice(0, 2).join('.') }}
      </div>
    </div>
    <div class="state-status">
      <div class="state-status_item state-wait"
           v-if="state === 0"
           :style="{background: variants[variant].wait[0]}"
      >
        <div class="state-status_role"
             :style="{'background-color': variants[variant].wait[1], 'border-color': variants[variant].wait[0]}"
        ></div>
      </div>
      <div class="state-status_item state-during"
           v-else-if="state === 1"
           :style="{background: variants[variant].during[0]}"
      >
        <div class="state-status_role"
             :style="{'background-color': '#FFF', 'border-color': variants[variant].during[1]}"
        ></div>
      </div>
      <div class="state-status_item state-ready"
           v-else-if="state === 2"
           :style="{background: variants[variant].ready[0]}"
      >
        <div class="state-status_role"
             :style="{'background-color': variants[variant].ready[1], 'border-color': variants[variant].ready[0]}"
        ></div>
      </div>
    </div>
    <div :class="delivery ? 'name-status1' : 'name-status'">
      {{ name }}
    </div>
    <div class="document-status"></div>
  </div>
</template>

<script>
export default {
  name: "stepWay",
  data() {
    return {
      variants: {
        green: {
          ready: ['#28C76F', '#28C76F'],
          wait: ['#E5F8ED', '#FFF'],
          during: ['repeating-linear-gradient(60deg, #9CD7B7, #9CD7B7 4px, #AAF0C8 0, #AAF0C8 8px)', '#E5F8ED'],
        },
        purple: {
          ready: ['#7E93DE', '#7E93DE'],
          wait: ['rgb(212 218 239)', '#FFF'],
          during: ['repeating-linear-gradient(60deg, rgb(212 218 239), rgb(212 218 239) 4px, #7E93DE 0, #7E93DE 8px)', 'rgb(212 218 239)'],
        }
      }
    }
  },
  props: {
    variant: {
      type: String,
      default() {
        return 'green'
      },
    },
    state: Number,
    name: String,
    date: String,
    docName: String,
    docType: String,
    delivery: Boolean,
    total: Number
  }
}
</script>

<style scoped>
.state-status_item {
  border-radius: 6px;
  min-height: 6px;
  max-height: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.date-status {
  background-color: #F8F8F8;
  border-radius: 6px;
  font-size: 12px;
  padding: 3px 5px;
  margin-bottom: 10px;
}

.date-status-container {
  min-height: 34px;
}

.state-status_role {
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 16px;
  position: relative;
  right: -2px;
  z-index: 1;
}

.step-way-container {
  width: 100%;
  text-align: end;
}

.name-status, .name-status1 {
  white-space: nowrap;
  padding: 5px 5px 0 0;
  display: flex;
  justify-content: end;
}

@media (max-width: 570px) {
  .name-status {
    transform: rotate(313deg);
    transform-origin: bottom right;
    padding: 0;
  }

  .step-way-container-height {
    height: 120px;
  }

}

@media (max-width: 380px) {
  .name-status {
    display: flex;
    justify-content: end;
  }

}

@media (max-width: 750px) {
  .name-status1 {
    transform: rotate(313deg);
    transform-origin: bottom right;
    padding: 0;
  }
  .step-way-container-height1 {
    height: 160px;
  }
}

</style>