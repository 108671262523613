<template>
  <div class="shipment-container">
    <div class="d-flex flex-wrap justify-content-between key-header mb-2 align-items-start">
      <div class="mb-1">
        <h3>
          Список отгрузок
        </h3>
      </div>
      <b-button
          v-b-modal.add_products_to_shipment
          class="text-nowrap" variant="primary"
      >
        + Добавить отгрузку
      </b-button>
    </div>
    <b-card class="filter-panel" body-class="p-0">
      <div class="p-2 d-flex flex-wrap justify-content-between align-items-start">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Поиск:</label>
          <b-form-input
              placeholder="по отгрузкам, товарам"
              type="text"
              class="d-inline-block"
              @input="findFilter"
          />
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-1 mr-1 d-flex align-items-center">
            <label class="mr-1">Сортировать:</label>
            <b-form-select
                v-model="dateFilter"
                :options="listDate"
            />
          </div>
          <div class="d-flex mt-1 align-items-center">
            <label class="mr-1">Статус:</label>
            <b-form-select
                v-model="statusFilter"
                :options="listStatus"
            />
          </div>
        </div>
      </div>
    </b-card>
    <div class="container-card">
      <card-shipment
          v-for="(shipment, i) in shipments"
          :key="i"
          :dataItem="shipment"
      />
    </div>
    <b-modal class="add-products-to-shipment"
             id="add_products_to_shipment"
             hide-header
             hide-footer
             modal-class="modal-primary"
             centered
             @hidden="clearSelected"
             size="xl"
    >
      <h4>Выберите товары для отгрузки</h4>
      <div class="px-2 pb-2 d-flex flex-wrap justify-content-between align-items-start">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Поиск:</label>
          <b-form-input
              placeholder="Поиск"
              type="text"
              class="d-inline-block"
              @input="findFilterProducts"
          />
        </div>
        <div class="d-flex flex-wrap">
          <div class="mt-1 mr-1 d-flex align-items-center">
            <label class="mr-1">Бренд:</label>
            <b-form-select
                v-model="brandFilter"
                :options="listAllBrands"
            />
          </div>
          <div class="d-flex mt-1 align-items-center">
            <label class="mr-1">Категория:</label>
            <b-form-select
                v-model="objectFilter"
                :options="listAllCategories"
            />
          </div>
        </div>
      </div>
      <table-load-products
          :findFilter="findFilterProducts"
          :brandFilter="brandFilter"
          :objectFilter="objectFilter"
          @details="showDetails"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BModal,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow, VBModal
} from 'bootstrap-vue'
import {mapActions, mapState} from "vuex";
import cardShipment from "@/components/shipments/cardShipment";
import tableLoadProducts from "@/components/product/tableLoadProducts";

export default {
  components: {
    BCard,
    cardShipment,
    BButton,
    BCol,
    BModal,
    BRow,
    BFormInput,
    BFormGroup,
    BFormSelect,
    tableLoadProducts
  },
  directives: {
    'b-modal': VBModal
  },
  name: "shipments",
  data() {
    return {
      findFilter: "по отгрузкам, товарам",
      listDate: [
        {value: '1', text: "дата приемки"},
        {value: '2', text: "дата создания"},
        {value: '3', text: "количество товаров"},
      ],
      dateFilter: "1",
      listStatus: [
        {value: '1', text: "отгрузка создана"},
        {value: '2', text: "обработка"},
        {value: '3', text: "в пути"},
        {value: '4', text: "приемка"},
        {value: '5', text: "принято"},
      ],
      statusFilter: "1",
      findFilterProducts: '',
      brandFilter: '',
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },
  methods: {
    ...mapActions('products', ["clearSelected"]),
    showDetails(id) {
      this.currentDetailID = id;
      this.show = true
    },
  },
  computed: {
    ...mapState('shipments', ["shipments"]),
    ...mapState('products', ["listAllBrands", "listAllCategories"]),
  }
}
</script>

<style scoped>

</style>