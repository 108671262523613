<template>
  <div class="table-container">
    <b-table
        id="table_products_to_shipment"
        responsive
        :thead-tr-class="'text-nowrap'"
        :fields="fields"
        :items="productsToShipment">
      <template #cell(name)="data">
        <product-cell
            :imgData="{
            id: data.item.id,
            img: data.item.firstImg,
            name: data.item.name,
            article: data.item.article,
            brand: data.item.brand
        }"
        />
      </template>
      <template #cell(amount)="data">
        {{ data.item.amountBySize.reduce((acc, size) => size.amount + acc, 0) }}
      </template>
      <template #cell(amountUser)="data">
        {{ data.item.amountBySize.reduce((acc, size) => size.amountUser + acc, 0) }}
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BRow,
  BTable,
} from 'bootstrap-vue';

import ProductCell from "@/components/ProductCell";

export default {
  name: "ShipmentTableProducts",
  components: {
    ProductCell,
    BCard,
    BCol,
    BRow,
    BFormInput,
    BFormCheckbox,
    BTable,
    BFormGroup,
    BFormSelect,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'продукт',
        },
        {
          key: 'amount',
          label: 'кол-во',
          sortable: true,
        },
        {
          key: 'amountUser',
          label: 'кол-во фактическое',
          sortable: true,
        },
      ],
    }
  },
  props: {
    productsToShipment: Array
  },
}
</script>

<style scoped>

</style>