<template>
  <div class="list-products-container">
    <b-table
        id="table_load_products"
        responsive
        :thead-tr-class="'text-nowrap'"
        :fields="fields"
        first-number
        :current-page="currentPage"
        :per-page="perPage"
        show-empty
        last-number
        :details-td-class="'p-0'"
        :items="productsWidthWB"
    >
      <template #empty="scope">
        <h4 class="load_products load_products_process"
            v-if="!loadProductsProcess"
        >
          Список товаров загружается ...
        </h4>
        <h4 class="load_products load_products_process"
            v-else-if="loadProductsProcess === true"
        >
          Список товаров пуст
        </h4>

      </template>
      <template #head(marketplaces)="data">
        <div style="width: 50px">{{ data.label }}</div>
      </template>
      <template #head(storage)="data">
        <div style="width: 50px">{{ data.label }}</div>
      </template>
      <template #head(preparation)="data">
        <div style="width: 130px">{{ data.label }}</div>
      </template>
      <template #head(stockmp)="data">
        <div style="width: 50px">{{ data.label }}</div>
      </template>
      <template #head(wayMp)="data">
        <div style="width: 101px">{{ data.label }}</div>
      </template>
      <template #head(menu)="data">
        <div style="width: 100px">{{ data.label }}</div>
      </template>
      <template #head(menu)="data">
        Выбрать
      </template>
      <template #head(selectAll)="data">
        <div style="width: 20px">
          <b-form-checkbox
              @change="selectAllProducts"
              v-bind:checked="getCurrState"
          />
        </div>
      </template>
      <template #cell(name)="data">
        <product-cell
            @openDetail="data.toggleDetails()"
            :_showDetails="data.item._showDetails"
            :detailsShowing="Array.isArray(data.item.stockff) || Array.isArray(data.item.stockmp)"
            :imgData="{
            id: data.item.id,
            img: data.item.img[0],
            name: data.item.name,
            article: data.item.article,
            brand: data.item.brand,
          }"
        />
      </template>
      <template #cell(sebes)="data">
        <div style="width: 200px" class="d-flex align-items-center">
          <b-form-input
              type="number"
              :autofocus="data.index === 0"
              style="max-width: 70px"
              v-model="data.item.sebes"
              placeholder="Себестоимость"
          />
          <b-button
              variant="primary"
              class="btn-icon ml-1"
              :disabled="getStateIcon === 'load_' + data.item.nmID"
              @click="function() {sendSebesC(data.item.nmID)}"
          >
            <feather-icon icon="CheckIcon"
                          v-if="getStateIcon === 'check_' + data.item.nmID"
            />
            <b-spinner small
                       v-else-if="getStateIcon === 'load_' + data.item.nmID"
            />
            <feather-icon icon="SaveIcon"
                          v-else
            />
          </b-button>
        </div>
      </template>
      <template #cell(marketplaces)="data">
        <div style="width: 30px; color: #F01A8A">WB</div>
      </template>
      <template #cell(storage)="data">
        <div>
          {{
            Array.isArray(data.item.stockff) ? data.item.stockff.reduce((a, c) => {
              return a + c.stock.storage
            }, 0) : data.item.stockff || 0
          }}
        </div>
      </template>
      <template #cell(preparation)="data">
        {{
          Array.isArray(data.item.stockff) ? data.item.stockff.reduce((a, c) => {
            return a + c.stock.preparation
          }, 0) : data.item.stockff
        }}
      </template>
      <template #cell(wayMp)="data">
        {{
          Array.isArray(data.item.stockff) ? data.item.stockff.reduce((a, c) => {
            return a + c.stock.wayMp
          }, 0) : data.item.stockff
        }}
      </template>
      <template #cell(stockmp)="data">
        {{
          Array.isArray(data.item.stockmp) ? data.item.stockmp.reduce((a, c) => {
            return a + c.stock
          }, 0) : data.item.stockmp || 0
        }}
      </template>
      <template #cell(menu)="data">
        <div class="cursor-pointer d-flex align-items-center justify-content-center m-auto" style="width: 18px"
             @click="sendIdDetails(data.item.id)">

          <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#979797"/>
            <circle cx="2" cy="9" r="2" fill="#979797"/>
            <circle cx="2" cy="16" r="2" fill="#979797"/>
          </svg>
        </div>
      </template>
      <template #row-details="data">
        <table-details-size
            :productId="data.item.id"
        />
      </template>
      <template #cell(selectAll)="data">
        <check-box-cell
            style="width: 20px"
            :productId="data.item.nmID"
        />
      </template>
    </b-table>
    <div class="block-pagination my-1">
      <b-row>
        <b-col
            cols="12">
          <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              first-number
              last-number
              align="center"
              prev-class="prev-item"
              next-class="next-item">
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BLink,
  BRow,
  BSpinner,
  BTable,
  BPagination
} from 'bootstrap-vue';

import ProductCell from "@/components/ProductCell";
import {mapActions, mapState} from "vuex";
import checkBoxCell from "@/components/checkBoxCell";
import TableDetailsSize from "@/components/product/TableDetailsSize";

export default {

  components: {
    checkBoxCell,
    ProductCell,
    BCard,
    BCol,
    BRow,
    BFormInput,
    BFormCheckbox,
    BTable,
    BSpinner,
    BButton,
    BFormGroup,
    BFormSelect,
    TableDetailsSize,
    BPagination
  },

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      stateIcon: 'wait',
      currentSebes: null,
      fields: [
        {
          key: 'name',
          label: 'Наименование',
          sortable: true,
        },
        {
          key: 'sebes',
          label: 'Себес',
          sortable: true,
        },
        {
          key: 'marketplaces',
          label: 'МП',
        },
        {
          key: 'storage',
          label: 'На ФФ ',
          sortable: true,
        },
        {
          key: 'preparation',
          label: 'Готовится на МП',
          sortable: true,
        },
        {
          key: 'wayMp',
          label: 'В пути на МП',
          sortable: true,
        },
        {
          key: 'stockmp',
          label: 'на МП',
          sortable: true,
        },
        {
          key: 'menu',
        },
        {
          key: 'selectAll',
        },
      ],
    }
  },
  name: "tableLoadProducts",

  computed: {
    getStateIcon() {
      return this.stateIcon
    },

    rows() {
      return this.totalNumberProducts
    },

    getCurrState() {
      return this.selectedProductsId.length > 0;
    },
    ...mapState('products', ['listAllCategories', "listAllBrands", "productsWidthWB", "selectedProductsId", "totalNumberProducts", "loadProductsProcess"])
  },

  methods: {

    async sendSebesC(nmId, sebes) {
      this.stateIcon = 'load_' + nmId;
      await this.sendSebes({nmId, sebes});
      this.stateIcon = 'check_' + nmId;
      setTimeout(_ => {
        this.stateIcon = 'wait'
      }, 2000)
      this.currentSebes = null;
    },

    sendIdDetails(id) {
      this.$emit('details', id)
    },
    ...mapActions('products', ["selectAllProducts", 'setPage', "loadNomenclatures", 'sendSebes'])
  },
  watch: {

  }
}
</script>

<style scoped>
.load_products {
  color: #d9d9d9;
  text-align: center;
  font-size: 44px;
  margin: 54px 0 18px 0;
}
</style>