<template>
  <b-card class="shipment-item-container">
    <div class="shipment-header">
      <h3 class="d-flex align-items-start flex-wrap">
        <div class="mr-1" style="margin-bottom: 5px">
          Отгрузка #{{ dataItem.id }} от {{ dataItem.createAt }}
        </div>
        <b-badge style="font-size: 15px" :variant="getStatus.color">
          {{ getStatus.label }}
        </b-badge>
      </h3>
      <div class="volume-shipment"
           v-if="dataItem.amountBox || dataItem.amountPallet || dataItem.totalVolume">
        Объем отгрузки:
        <span>{{ dataItem.totalVolume }} м/куб; </span>
        <span v-if="dataItem.amountBox">10 коробов</span>
        <span v-if="dataItem.amountPallet">; 5 паллет</span>
      </div>
    </div>
    <div class="stats-container d-flex">
      <step-way
          v-for="(status, i) in statusesPresent"
          :key="i"
          variant="green"
          :state="getCurrentStatus(status.name, i)"
          :name="status.label"
          :date="dataItem.statuses[status.name].date"
          :docName="dataItem.statuses[status.name].docName"
          :docType="dataItem.statuses[status.name].docType"
          :total="Object.keys(dataItem.statuses).length"
      />
    </div>
    <div class="my-2 shipment-data  d-flex align-items-center justify-content-end">
      <div @click="showShipmentTable = !showShipmentTable" class="cursor-pointer d-flex align-items-center">
        <span class="mr-1">список товаров</span>
        <svg v-if="showShipmentTable" width="12" height="12" viewBox="0 0 12 12"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M11.025 4.275L6.525 8.775C6.375 8.925 6.225 9 6 9C5.775 9 5.625 8.925 5.475 8.775L0.975 4.275C0.675 3.975 0.675 3.525 0.975 3.225C1.275 2.925 1.725 2.925 2.025 3.225L6 7.2L9.975 3.225C10.275 2.925 10.725 2.925 11.025 3.225C11.325 3.525 11.325 3.975 11.025 4.275Z"
              fill="#6E6B7B"/>
        </svg>
        <svg v-else width="12" height="12" viewBox="0 0 12 12" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M4.275 0.975L8.775 5.475C8.925 5.625 9 5.775 9 6C9 6.225 8.925 6.375 8.775 6.525L4.275 11.025C3.975 11.325 3.525 11.325 3.225 11.025C2.925 10.725 2.925 10.275 3.225 9.975L7.2 6L3.225 2.025C2.925 1.725 2.925 1.275 3.225 0.975C3.525 0.675 3.975 0.675 4.275 0.975Z"
              fill="#6E6B7B"/>
        </svg>
      </div>
    </div>
    <shipment-table-products
        v-if="showShipmentTable"
        :products-to-shipment="dataItem.products"
    />
  </b-card>
</template>

<script>
import {
  BBadge,
  BCard,
} from 'bootstrap-vue';
import stepWay from "@/components/stepWay";
import shipmentTableProducts from "@/components/shipments/ShipmentTableProducts";


export default {
  components: {
    BBadge,
    BCard,
    stepWay,
    shipmentTableProducts,
  },
  data() {
    return {
      statusesPresent: [
        {name: 'create', label: "создана", color: "light-primary"},
        {name: 'handling', label: "обработка", color: "light-primary"},
        {name: 'onWay', label: "в пути", color: "light-primary"},
        {name: 'acceptance', label: "приемка", color: "light-primary"},
        {name: 'accepted', label: "принята", color: "light-success"},
      ],
      showShipmentTable: false,
      currentStatus: null
    }
  },
  name: "cardShipment",
  props: {
    dataItem: Object,
    dataDelivery: Object,
  },
  computed: {
    getStatus() {
      return this.statusesPresent.find(st => st.name === this.dataItem.currentStatus);
    },
  },
  methods: {
    getCurrentStatus(name, i) {
      if (this.dataItem.currentStatus === name) {
        if (Object.keys(this.dataItem.statuses).length - 1 === i) return 2;
        else {
          this.currentStatus = i;
          return 1;
        }
      }
      if (typeof this.currentStatus === "number" && this.currentStatus < i) {
        return 0;
      }
      return 2
    }
  },
}
</script>

<style scoped>

.shipment-header {
  margin-bottom: 30px;
}

</style>